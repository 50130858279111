import React from 'react';
import styled from 'styled-components';

import { Container } from '../global';

const Footer = () => {
  const curYear = new Date().getFullYear();
  return (
    <FooterWrapper id="footer">
      <FooterColumnContainer>
        <FooterColumn>
          <FooterLink href="https://support.dealmap.com" target="_blank">
            Support
          </FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterLink href="/#features">Features</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterLink href="#">Terms of Service</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterLink href="#">Privacy</FooterLink>
        </FooterColumn>
      </FooterColumnContainer>
      <div style={{ textAlign: 'center', height: '100%', marginTop: '35px' }}>
        © {curYear} Deal Map. All rights reserved. Various trademarks held by their respective
        owners.
      </div>
    </FooterWrapper>
  );
};

const FooterLink = styled.a`
  text-decoration: none;
  color: black;
  font-weight: 600;
`;

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`;

const FooterColumnContainer = styled(Container)`
  display: grid;
  text-align: center;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`;
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`;

export default Footer;
